// blob follower
const bodyScrollMax = document.body.offsetHeight - 115 - window.innerHeight / 2;

const blob = document.getElementById("blob");

document.body.onpointermove = (e) => {
  const { pageX, pageY } = e;

  blob.animate(
    {
      left: `${pageX - 250}px`,
      top: `${Math.min(bodyScrollMax, pageY) - 250}px`,
    },
    { duration: 2000, fill: "forwards" }
  );
};

window.onscroll = function (_) {
  blob.animate(
    {
      top: `${Math.min(bodyScrollMax, window.scrollY) + 250}px`,
    },
    { duration: 2000, fill: "forwards" }
  );
};
